import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useQueryParam, StringParam } from 'use-query-params'
import { useTranslation } from 'react-i18next'

import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import { useAuth0 } from 'utils/react-auth0-spa'
import {
  StripeCheckoutRedirectContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
  ShapeContainer,
} from 'components/StripeCheckout'
import Loading from 'components/Loading'
import LottieShape from 'components/LottieShape'
import AbstractPaymentAnimation from 'components/Animations/AbstractPaymentAnimation'
import GlobalStyles from 'styles/GlobalStyles'

const InputBox = styled.input`
  width: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  background: #dbf8ff;
  margin-bottom: 3em;
`

const Redeem = () => {
  const SUBSCRIPTION_NAME = 'FluentWorlds Monthly'
  const REDEEM_PATH = '/aquipago/redeem/'
  const ACCOUNT_PATH = '/account/'
  const PROMO_CODE_QUERY_PARAM = 'promo'
  const SOURCE_AQUIPAGO = 'AQUIPAGO'

  const {
    productAccess,
    fetchUserProductAccess,
    userStateLoading,
  } = useContext(UserContext)
  const {
    subscriptions,
    fetchAvailableSitePlans,
    subscriptionStateLoading,
    createStripeSubscription,
  } = useContext(SubscriptionContext)
  const { auth0Loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const { t, i18n, ready } = useTranslation('promoPage')
  const [promoCodeQueryParam] = useQueryParam(
    PROMO_CODE_QUERY_PARAM,
    StringParam,
  )
  const [promoCodeText, setPromoCodeText] = useState('')
  const [isPromoInvalid, setIsPromoInvalid] = useState(false) // flag to invalidate promo code so it won't be retried
  const [isRedirectAuth0Loading, setIsRedirectAuth0Loading] = useState(false)
  const [isRedirectStripeLoading, setIsRedirectStripeLoading] = useState(false)

  // when the user is logged in and subscriptions haven't yet been fetched then fetch the available site plans
  useEffect(() => {
    const isUserAuthenticated = !auth0Loading && isAuthenticated

    if (
      isUserAuthenticated &&
      !subscriptionStateLoading &&
      subscriptions &&
      subscriptions.length === 0
    ) {
      fetchAvailableSitePlans()
      if (!productAccess) {
        fetchUserProductAccess()
      }
    }
  }, [
    auth0Loading,
    subscriptionStateLoading,
    fetchAvailableSitePlans,
    fetchUserProductAccess,
    isAuthenticated,
    productAccess,
    subscriptions,
  ])

  // prompt user to sign in if unauthenticated or attempt to create a Stripe
  // subscription if the user has the promo code in the query string
  useEffect(() => {
    const isUserAuthenticated = !auth0Loading && isAuthenticated
    const isUserUnauthenticated = !auth0Loading && !isAuthenticated
    if (isUserUnauthenticated && promoCodeQueryParam === undefined) {
      setIsRedirectAuth0Loading(true)
      loginWithRedirect({
        redirect_uri: process.env.GATSBY_WEBSITE_URL + REDEEM_PATH,
        ui_locales: i18n.language.substring(0, 2),
      })
    } else if (isUserUnauthenticated && promoCodeQueryParam !== undefined) {
      setIsRedirectAuth0Loading(true)
      loginWithRedirect({
        redirect_uri:
          process.env.GATSBY_WEBSITE_URL +
          REDEEM_PATH +
          '?' +
          PROMO_CODE_QUERY_PARAM +
          '=' +
          promoCodeQueryParam,
        ui_locales: i18n.language.substring(0, 2),
      })
    } else if (
      isUserAuthenticated &&
      !subscriptionStateLoading &&
      !isRedirectStripeLoading &&
      promoCodeQueryParam !== undefined &&
      subscriptions &&
      subscriptions.length > 0 &&
      !isPromoInvalid
    ) {
      setIsRedirectStripeLoading(true)
      createStripeSubscription(
        SUBSCRIPTION_NAME,
        null,
        SOURCE_AQUIPAGO,
        null,
        promoCodeQueryParam.trim(),
      )
      setIsPromoInvalid(true) // invalidate promo code
      setIsRedirectStripeLoading(false)
    }
  }, [
    auth0Loading,
    subscriptionStateLoading,
    isRedirectStripeLoading,
    isAuthenticated,
    promoCodeQueryParam,
    subscriptions,
    i18n.language,
    loginWithRedirect,
    createStripeSubscription,
    productAccess,
    isPromoInvalid,
  ])

  const handleInputChange = (e) => {
    setPromoCodeText(e.currentTarget.value)
  }

  const submitPromoCode = async () => {
    if (promoCodeText === '') {
      window.alert('Please enter in a valid promotion code')
      return
    }

    setIsRedirectStripeLoading(true)
    createStripeSubscription(
      SUBSCRIPTION_NAME,
      null,
      SOURCE_AQUIPAGO,
      null,
      promoCodeText.trim(),
    )
    setIsRedirectStripeLoading(false)
  }

  const showLoading =
    subscriptionStateLoading ||
    userStateLoading ||
    auth0Loading ||
    isRedirectAuth0Loading ||
    isRedirectStripeLoading ||
    !ready

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <StripeCheckoutRedirectContainer>
            <ShapeContainer>
              <LottieShape
                size={500}
                animationData={AbstractPaymentAnimation}
              />
            </ShapeContainer>
            <StripeCheckoutContentContainer>
              <StripeCheckoutRedirectDescription>
                {t('prompt')} {SUBSCRIPTION_NAME}:
              </StripeCheckoutRedirectDescription>
              <InputBox onChange={handleInputChange} />
              <StripeCheckoutRedirectButton onClick={() => submitPromoCode()}>
                {t('submit')}
              </StripeCheckoutRedirectButton>
              <Link
                css={`
                  width: 300px;
                  margin-top: 3em;
                `}
                to={ACCOUNT_PATH}
              >
                <StripeCheckoutRedirectButton>
                  {t('goToAccount')}
                </StripeCheckoutRedirectButton>
              </Link>
            </StripeCheckoutContentContainer>
          </StripeCheckoutRedirectContainer>
        </>
      )}
    </>
  )
}

export default Redeem
